import "./AboutUs.scss";
import { ReactComponent as PhoneIcon } from "../../assets/icons/aboutus_phone_icon.svg";
import { ReactComponent as PhoneEnIcon } from "../../assets/icons/aboutus_phone_icon_en.svg";
import { ReactComponent as PhoneIdIcon } from "../../assets/icons/aboutus_phone_icon_id.svg";
import { ReactComponent as BikeIcon } from "../../assets/icons/aboutus_bike_icon.svg";
import { ReactComponent as BikeIconMobileSvg } from "../../assets/icons/aboutus_bike_mobile.svg";
import { ReactComponent as SafetyIcon } from "../../assets/icons/aboutus_safety_icon.svg";
import SafetyIconMobileImage from "../../assets/images/safety_icon_mobile.png";
import { ReactComponent as BackgroundOne } from "../../assets/icons/aboutus_bg_one.svg";
import { ReactComponent as BackgroundTwo } from "../../assets/icons/aboutus_bg_one.svg";
import { ReactComponent as BackgroundThree } from "../../assets/icons/aboutus_bg_one.svg";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const renderTextWithLineBreaks = (text: string) => {
        return text.split("\n").map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    return (
        <div
            id="aboutUsSection"
            className="about-us container"
        >
            <div className="about-us__inner">
                <div>
                    <span className="about-us__title">{t("aboutUs.title")}</span>
                    <div className="about-us__content">
                        <div className="about-us__item">
                            <BackgroundOne className="about-us__background-icon" />
                            <span className="about-us__subtitle about-us__subtitle--one">
                                {t("aboutUs.subtitleOne")}
                            </span>

                            {currentLanguage === "ru" && <PhoneIcon className="about-us__phone-icon" />}

                            {currentLanguage === "en" && <PhoneEnIcon className="about-us__phone-icon" />}

                            {currentLanguage === "id" && <PhoneIdIcon className="about-us__phone-icon" />}

                            <p className="about-us__description">{t("aboutUs.descriptionOne")}</p>
                        </div>
                        <div className="about-us__item">
                            <BackgroundTwo className="about-us__background-icon" />
                            <BikeIconMobileSvg className="about-us__bike-icon-mobile-bike" />
                            <span className="about-us__subtitle">
                                {renderTextWithLineBreaks(t("aboutUs.subtitleTwo"))}
                            </span>
                            <BikeIcon className="about-us__bike-icon" />
                            <p className="about-us__description">{t("aboutUs.descriptionTwo")}</p>
                        </div>
                        <div className="about-us__item">
                            <BackgroundThree className="about-us__background-icon" />
                            <img
                                src={SafetyIconMobileImage}
                                className="about-us__safety-image-mobile"
                            />
                            <div className="about-us__item-inner">
                                <span className="about-us__subtitle">{t("aboutUs.subtitleThree")}</span>
                                <p className="about-us__description">{t("aboutUs.descriptionThree")}</p>
                            </div>
                            <SafetyIcon className="about-us__safety-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
