import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as LanguageIcon } from "../../assets/icons/languageIcon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import Ellipse from "../../assets/images/mobile_menu_ellipse.png";
import MenuItems from "../MenuItems/MenuItems";
import StoreButton from "../StoreButton/StoreButton";
import "./Header.scss";

const Header: React.FC = () => {
    const { i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setIsDropdownOpen(false);
    };

    return (
        <header className="header">
            <nav className="header__navbar">
                <div className="header__logo">
                    <Logo />
                </div>
                <div className="header__menu-desktop">
                    <MenuItems activeColor="#28A889" />
                </div>
                <div className={`header__menu-items ${isMenuOpen ? "header__menu-items--show" : ""}`}>
                    <div className="header__menu-items-inner">
                        <div className="header__menu-mobile">
                            <MenuItems
                                activeColor="#28A889"
                                handleOpenMenu={toggleMenu}
                            />
                        </div>
                        <img
                            className="header__ellipse"
                            src={Ellipse}
                            alt="Ellipse"
                        />
                        <div className="header__language">
                            <LanguageIcon
                                onClick={toggleDropdown}
                                className="header__language-icon"
                            />
                            <span
                                onClick={toggleDropdown}
                                className="header__language-text"
                            >
                                {i18n.language.toUpperCase()}
                            </span>
                            {isDropdownOpen && (
                                <div className="header__dropdown">
                                    <div
                                        onClick={() => changeLanguage("ru")}
                                        className="header__dropdown-item"
                                    >
                                        <LanguageIcon className="header__language-icon" /> RU
                                    </div>
                                    <div
                                        onClick={() => changeLanguage("en")}
                                        className="header__dropdown-item"
                                    >
                                        <LanguageIcon className="header__language-icon" /> EN
                                    </div>
                                    <div
                                        onClick={() => changeLanguage("id")}
                                        className="header__dropdown-item"
                                    >
                                        <LanguageIcon className="header__language-icon" /> IND
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="header__store-buttons">
                            <StoreButton
                                titleColor="#2f2f2f"
                                subtitleColor="#2f2f2f"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="header__menu-toggle"
                    onClick={toggleMenu}
                >
                    {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </div>
            </nav>
            {isMenuOpen && (
                <div
                    className="header__menu-overlay"
                    onClick={closeMenu}
                ></div>
            )}
        </header>
    );
};

export default Header;
