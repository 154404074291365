import AboutUs from "../../components/AboutUs/AboutUs";
import Application from "../../components/Application/Application";
import CaringOfEnviroment from "../../components/CaringOfEnviroment/CaringOfEnviroment";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Intro from "../../components/Intro/Intro";
import OurBikes from "../../components/OurBikes/OurBikes";
import styles from "./MainPage.module.scss";

const MainPage = () => {
  return (
    <div className={styles.mainPage}>
      <Header />
      <Intro />
      <CaringOfEnviroment />
      <AboutUs />
      <Application />
      <OurBikes />
      <Contact />
      <Footer />
    </div>
  );
};
export default MainPage;
