import "./Footer.scss";
import { ReactComponent as Logo } from "../../assets/icons/footerLogo.svg";
import MenuItems from "../MenuItems/MenuItems";
import StoreButton from "../StoreButton/StoreButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as Facebook } from "../../assets/icons/facebook_24.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram_24.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube_24.svg";
import { facebookLink, instagramLink, youtubeLink } from "../../constants/constants";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer__inner">
                <div className="footer__address">
                    <div className="footer__address">
                        <Logo className="footer__logo" />
                        <span className="footer__email">main@eco-bike.org</span>
                        <span className="footer__company-name">{t("footer.companyName")}</span>
                        <span className="footer__address-text">{t("footer.address")}</span>
                    </div>

                    <div className="footer__socials">
                        <a
                            className="footer__socials-button"
                            rel="noreferrer"
                            href={facebookLink}
                            target="_blank"
                        >
                            <Facebook />
                        </a>

                        <a
                            className="footer__socials-button"
                            rel="noreferrer"
                            href={instagramLink}
                            target="_blank"
                        >
                            <Instagram />
                        </a>

                        <a
                            className="footer__socials-button"
                            href={youtubeLink}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Youtube />
                        </a>
                    </div>
                </div>
                <div className="footer__right">
                    <div className="footer__menu-items">
                        <MenuItems activeColor="#ffffff" />
                    </div>
                    <div className="footer__store-buttons">
                        <StoreButton
                            titleColor="#ffffff"
                            subtitleColor="#ffffff"
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
