import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/app.global.scss";
import App from "./App";
import "./i18n";

// REMOVE INSTALL PROMPT ON MOBILE
window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
