import "./Contact.scss";
import { ReactComponent as CityBikeIcon } from "../../assets/icons/contacticon.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { validateEmail } from "../../utils/emailValidation";

const Contact = () => {
    const { t } = useTranslation();
    const [comment, setComment] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const newErrors: { [key: string]: string } = {};

        if (!comment || comment.length < 10) {
            newErrors.comment = t("form.errors.commentInvalid");
        }
        if (!email || !validateEmail(email)) {
            newErrors.email = t("form.errors.emailInvalid");
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // Form is valid, submit the data
            fetch("https://api.eco-bike.org/feed-back/send", {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({
                    email: email,
                    comment: comment,
                }),
            })
                .then(() => {
                    window.alert(t("form.successMessage"));
                    setErrors({});
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <div
            id="contactsSection"
            className="contact__wrapper"
        >
            <div className="contact__text-section">
                <h1 className="contact__title">{t("contact.title")}</h1>
                <p className="contact__subtitle">{t("contact.subtitle")}</p>
            </div>
            <div className="contact__form-section">
                <form
                    className="contact__form"
                    onSubmit={handleSubmit}
                >
                    <div className="contact__input-group">
                        <label htmlFor="email">{t("contact.emailLabel")}</label>
                        <input
                            type="email"
                            id="email"
                            placeholder={t("contact.emailPlaceholder")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && <span className="form__error">{errors.email}</span>}
                    </div>
                    <div className="contact__input-group">
                        <label htmlFor="comment">{t("contact.commentLabel")}</label>
                        <textarea
                            id="comment"
                            placeholder={t("contact.commentPlaceholder")}
                            className="contact__textarea"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        {errors.comment && <span className="form__error">{errors.comment}</span>}
                    </div>
                    <button
                        type="submit"
                        className="contact__submit-button"
                    >
                        {t("contact.submitButton")}
                    </button>
                </form>
                <div className="contact__icon">
                    <CityBikeIcon />
                </div>
            </div>
        </div>
    );
};

export default Contact;
