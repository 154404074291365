import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "./Application.scss";
import SmartphonesImage from "../../assets/images/smartphones.png";
import SmartphonesEnImage from "../../assets/images/smartphones_en.png";
import SmartphonesIdImage from "../../assets/images/smartphones_id.png";
import SmartphonesTuristicImage from "../../assets/images/turisticbook_application.png";
import SmartphonesTuristicImageEn from "../../assets/images/turisticbook_application_en.png";
import SmartphonesTuristicImageId from "../../assets/images/turisticbook_application_id.png";
import SmartphonesMapImage from "../../assets/images/smartphones_map_application.png";
import SmartphonesMapImageEn from "../../assets/images/smartphones_map_application_en.png";
import SmartphonesMapImageId from "../../assets/images/smartphones_map_application_id.png";

import { ReactComponent as RoadMapLine } from "../../assets/icons/roadmapline_application.svg";
import { ReactComponent as RoadMapLineMobile } from "../../assets/icons/line_application_mobile.svg";
import { ReactComponent as TreeIcon } from "../../assets/icons/tree_application.svg";
import { ReactComponent as ElephantIcon } from "../../assets/icons/elephant_application.svg";
import { ReactComponent as TowerIcon } from "../../assets/icons/tower_application.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/b_icon_application.svg";
import { ReactComponent as IceCreamIcon } from "../../assets/icons/icecream_application.svg";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera_icon_application.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

type NavigationItem = {
    id: number;
    text: string;
    content: JSX.Element;
    bgColor: string;
    activeColor: string;
};

const Application: React.FC = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const navigations: NavigationItem[] = [
        {
            id: 3,
            text: t("application.nav.rental"),
            content: (
                <div className="application__content">
                    <div className="application__headings">
                        <span className="application__content-title">{t("application.rental.title")}</span>
                        <span className="application__subtitle">{t("application.rental.subtitle1")}</span>
                        <div className="application__info-content">
                            <div className="application__info-content-inner">
                                <div className="application__info-item">
                                    <span className="application__info-item-text">
                                        {t("application.rental.step1")}
                                    </span>
                                </div>
                                <div className="application__info-item">
                                    <span className="application__info-item-text">
                                        {t("application.rental.step2")}
                                    </span>
                                </div>
                            </div>
                            <div className="application__info-item-right">
                                <div className="application__info-item">
                                    <span className="application__info-item-text">
                                        {t("application.rental.step3")}
                                    </span>
                                </div>
                            </div>
                            <RoadMapLine className="application__roadmap" />
                            <RoadMapLineMobile className="application__roadmap-mobile" />
                        </div>
                    </div>
                    <div className="application__image">
                        <img
                            src={
                                currentLanguage === "en"
                                    ? SmartphonesEnImage
                                    : currentLanguage === "id"
                                    ? SmartphonesIdImage
                                    : SmartphonesImage
                            }
                            alt="smartphones"
                        />
                    </div>
                </div>
            ),
            bgColor: "application__content-wrapper--rental-background",
            activeColor: "#5D81FF",
        },
        {
            id: 1,
            text: t("application.nav.map"),
            content: (
                <div className="application__content">
                    <div className="application__headings">
                        <span className="application__content-title">{t("application.map.title")}</span>
                        <span className="application__subtitle">{t("application.map.subtitle1")}</span>
                        <span className="application__subtitle">{t("application.map.subtitle2")}</span>
                        <span className="application__subtitle">{t("application.map.subtitle3")}</span>
                    </div>
                    <div className="application__image">
                        <img
                            src={
                                currentLanguage === "en"
                                    ? SmartphonesMapImageEn
                                    : currentLanguage === "id"
                                    ? SmartphonesMapImageId
                                    : SmartphonesMapImage
                            }
                            alt="smartphones"
                        />
                    </div>
                </div>
            ),
            bgColor: "application__content-wrapper--map-background",
            activeColor: "#28A889",
        },
        {
            id: 2,
            text: t("application.nav.turisticBook"),
            content: (
                <div className="application__content">
                    <div className="application__headings">
                        <span className="application__content-title">
                            {t("application.turisticBook.title")}
                        </span>
                        <span className="application__subtitle">
                            {t("application.turisticBook.subtitle1")}
                        </span>
                        <span className="application__subtitle">
                            {t("application.turisticBook.subtitle2")}
                        </span>
                        <div className="application__icons">
                            <TreeIcon />
                            <ElephantIcon />
                            <TowerIcon />
                            <SunIcon />
                            <IceCreamIcon />
                            <CameraIcon />
                        </div>
                    </div>
                    <div className="application__image application__image--tree">
                        <img
                            src={
                                currentLanguage === "en"
                                    ? SmartphonesTuristicImageEn
                                    : currentLanguage === "id"
                                    ? SmartphonesTuristicImageId
                                    : SmartphonesTuristicImage
                            }
                            alt="smartphones"
                        />
                    </div>
                </div>
            ),
            bgColor: "application__content-wrapper--turistic-background",
            activeColor: "#FF7E54",
        },
    ];

    const [selectedNav, setSelectedNav] = useState<NavigationItem>(navigations[0]);
    const sliderRef = useRef<Slider>(null);

    const handleBeforeChange = (current: number, next: number) => {
        setSelectedNav(navigations[next]);
    };

    const handleNavClick = (index: number) => {
        setSelectedNav(navigations[index]);
        sliderRef.current?.slickGoTo(index);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        beforeChange: handleBeforeChange,
        dotsClass: `slick-dots application__dots`,
    };

    return (
        <div
            id="applicationSection"
            className="container"
        >
            <div className="application__inner">
                <span className="application__title">{t("application.title")}</span>
                <div className="application__navlinks">
                    {navigations.map((item, index) => (
                        <div
                            key={item.id}
                            className={`application__navlink ${
                                item.id === selectedNav.id ? "application__navlink--active" : ""
                            } ${item.bgColor}`}
                            onClick={() => handleNavClick(index)}
                            style={{
                                backgroundColor: item.id === selectedNav.id ? item.activeColor : "#f3f3f3",
                                color: item.id === selectedNav.id ? "white" : "inherit",
                            }}
                        >
                            <span className="application__link-text">{item.text}</span>
                        </div>
                    ))}
                </div>
                <div className={`application__content-wrapper ${selectedNav.bgColor}`}>
                    <Slider
                        ref={sliderRef}
                        {...settings}
                    >
                        {navigations.map((item) => (
                            <div key={item.id}>{item.content}</div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Application;
