import React from "react";
import { ReactComponent as AppStoreIcon } from "../../assets/icons/appStore_icon.svg";
import { ReactComponent as PlayMarketIcon } from "../../assets/icons/playmarket_icon.svg";
// import { ReactComponent as AppGalleryIcon } from "../../assets/icons/appGallery_icon.svg";
import { ReactComponent as AppStoreBlackIcon } from "../../assets/icons/appStoreBlack_icon.svg";
import { ReactComponent as QrAppStore } from "../../assets/images/qr-app-store.svg";
import { ReactComponent as QrPlayMarket } from "../../assets/images/qr-play-market.svg";
import { useTranslation } from "react-i18next";
import "./StoreButton.scss";
import { LINK_APP_MOBILE_APP_STORE, LINK_APP_MOBILE_PLAY_MARKET } from "../../constants/constants";

interface StoreButtonProps {
    titleColor: string;
    subtitleColor: string;
    qrCodes?: boolean;
}

const StoreButton: React.FC<StoreButtonProps> = ({ titleColor, subtitleColor, qrCodes = false }) => {
    const { t } = useTranslation();
    const buttons = [
        {
            icon: titleColor !== "#ffffff" ? <AppStoreBlackIcon /> : <AppStoreIcon />,
            title: t("storeButton.downloadOn"),
            subtitle: "App Store",
            link: LINK_APP_MOBILE_APP_STORE,
        },
        {
            icon: <PlayMarketIcon />,
            title: t("storeButton.getItOn"),
            subtitle: "Google Play",
            link: LINK_APP_MOBILE_PLAY_MARKET,
        },
        // {
        //     icon: <AppGalleryIcon />,
        //     title: t("storeButton.downloadOn"),
        //     subtitle: "AppGallery",
        // },
    ];

    if (qrCodes)
        return (
            <>
                <QrAppStore
                    style={{ marginRight: 25 }}
                    onClick={() => window.open(LINK_APP_MOBILE_APP_STORE, "_blank")}
                    cursor="pointer"
                />
                <QrPlayMarket
                    onClick={() => window.open(LINK_APP_MOBILE_PLAY_MARKET, "_blank")}
                    cursor="pointer"
                />
            </>
        );

    return (
        <>
            {buttons.map((button, index) => (
                <div
                    key={index}
                    className="store-button"
                    onClick={() => window.open(button.link, "_blank")}
                >
                    <div className="store-button__icon">{button.icon}</div>
                    <div className="store-button__text">
                        <span
                            className="store-button__title"
                            style={{ color: titleColor }}
                        >
                            {button.title}
                        </span>
                        <span
                            className="store-button__subtitle"
                            style={{ color: subtitleColor }}
                        >
                            {button.subtitle}
                        </span>
                    </div>
                </div>
            ))}
        </>
    );
};

export default StoreButton;
