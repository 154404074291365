import React, { useState } from "react";
import "./Form.scss";
import { validateEmail } from "../../utils/emailValidation";
import { formatPhone } from "../../utils/phoneMask";
import { useTranslation } from "react-i18next";

const Form: React.FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};

    if (!name) {
      newErrors.name = t("form.errors.nameRequired");
    }
    if (!phone || phone.length < 11) {
      newErrors.phone = t("form.errors.phoneInvalid");
    }
    if (!email || !validateEmail(email)) {
      newErrors.email = t("form.errors.emailInvalid");
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Form is valid, submit the data
      fetch("https://api.eco-bike.org/feed-back/send", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          email: email,
          name: name,
          phone: phone,
        }),
      })
        .then(() => {
          window.alert(t("form.successMessage"));
          setErrors({});
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhone(event.target.value);
    setPhone(formattedPhone || "");
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__input-group">
        <label htmlFor="name" className="form__label">
          {t("form.labels.name")}
        </label>
        <input
          type="text"
          id="name"
          className="form__input"
          placeholder={t("form.placeholders.name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <span className="form__error">{errors.name}</span>}
      </div>
      <div className="form__input-group">
        <label htmlFor="phone" className="form__label">
          {t("form.labels.phone")}
        </label>
        <input
          type="tel"
          id="phone"
          className="form__input"
          placeholder={t("form.placeholders.phone")}
          value={phone}
          onChange={handlePhoneChange}
        />
        {errors.phone && <span className="form__error">{errors.phone}</span>}
      </div>
      <div className="form__input-group">
        <label htmlFor="email" className="form__label">
          {t("form.labels.email")}
        </label>
        <input
          type="email"
          id="email"
          className="form__input"
          placeholder={t("form.placeholders.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <span className="form__error">{errors.email}</span>}
      </div>
      <button type="submit" className="form__submit-button">
        {t("form.submitButton")}
      </button>
    </form>
  );
};

export default Form;
